'use client';

import * as React from 'react';
import { useRouter } from 'next/navigation';
import { Input } from '@noirproperties/ui/components/ui/input';
import { Button } from '@noirproperties/ui/components/ui/button';
import { cn } from '@/lib/utils';

type SearchContext = {
  query: string;
  setQuery: (value: string) => void;
  onSearch: () => void;
};

const SearchContext = React.createContext<SearchContext | undefined>(undefined);

function useSearch() {
  const context = React.useContext(SearchContext);
  if (!context) {
    throw new Error('Search components must be used within SearchProvider');
  }
  return context;
}

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [query, setQuery] = React.useState('');
  const router = useRouter();

  const destination =
    `/properties/search?query=${encodeURIComponent(query)}` as const;

  const onSearch = () => {
    router.push(destination);
  };

  return (
    <SearchContext.Provider value={{ query, setQuery, onSearch }}>
      {children}
    </SearchContext.Provider>
  );
}
SearchProvider.displayName = 'SearchProvider';

export const SearchInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<typeof Input>
>(({ className, ...props }, ref) => {
  const { query, setQuery, onSearch } = useSearch();

  return (
    <Input
      autoComplete="off"
      className={cn(
        'text-md bg-fg h-20 flex-grow rounded-none sm:text-lg',
        className,
      )}
      onChange={(e) => {
        setQuery(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onSearch();
      }}
      ref={ref}
      spellCheck="false"
      value={query}
      {...props}
    />
  );
});
SearchInput.displayName = 'SearchInput';

export const SearchButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof Button>
>(({ className, children, ...props }, ref) => {
  const { onSearch } = useSearch();

  return (
    <Button
      className={cn(
        'h-20 min-w-20 flex-shrink-0 rounded-none shadow-sm',
        className,
      )}
      onClick={onSearch}
      ref={ref}
      size="icon"
      {...props}
    >
      {children}
    </Button>
  );
});
SearchButton.displayName = 'SearchButton';
